import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Header from "../components/Pages/AppStoreScreenshots/Header/Header";
import Templates from "../components/Pages/AppStoreScreenshots/Templates/Templates"
import Devices from "../components/Pages/AppStoreScreenshots/Devices/Devices"
import Video from "../components/Pages/AppStoreScreenshots/Video/Video"
import Cycle from "../components/Pages/AppStoreScreenshots/Cycle/Cycle"
import Features from "../components/Pages/AppStoreScreenshots/Features/Features"
import Testimonials from "../components/Pages/AppStoreScreenshots/Testimonials/Testimonials"
import Pricing from "../components/Common/Pricing/Pricing"

export default () => {
  return (
    <Layout>
      <SEO
        title={"App Store Screenshot Generator | Panoramic Mockup Sets"}
        description={
          "Create eye-catching screenshots for App Store & Google Play in minutes. Choose from 100s of pre-made templates to get started. It's free."
        }
        ogDescription={
          "Create eye-catching mockups for your app, in 3D."
        }
        twitterDescription={
          "Create eye-catching mockups for your app, in 3D."
        }
      />
      <Header /> 
      <Templates />
      <Devices />
      <Video />
      <Cycle />
      <Features />
      <Testimonials />
      <Pricing medium />
    </Layout>
  );
};
