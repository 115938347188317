import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "./devices.module.scss";

const AppStoreScreenshotsDevices = () => {

  return (
    <div className={styles.container}>
        <h3>
            Any device frame you need
        </h3>
        <h4>
            Choose from our latest collection of iPhones, iPads, Android Mobile & Tablet devices.<br /> Customize shapes, styles and sizes.
        </h4>
        <StaticImage
          src='../../../../assets/images/illustrations/homepage-screens.png'
          alt="Collection of multiple devices: Samsung S10, Samsung S20 and Apple Devices (iPhone, iPad, clay Iphone)"
          placeholder="blurred"
          layout="constrained"
          width={1300}
        />
    </div>
  );
};

export default AppStoreScreenshotsDevices;
