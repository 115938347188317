import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./video.module.scss";

const AppStoreScreenshotsCycle = () => {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <StaticImage
          src="../../../../assets/images/video-img-1.png"
          alt="Illusrtation of App Store Preview Video"
          placeholder="blurred"
          layout="constrained"
          width={700}
        />
      </div>
      <div className={styles.right}>
        <h3>Add Video Previews</h3>
        <h4>
          Engage your audience by making a short video to demo your app
          functionality and user experience.
        </h4>

        <ul>
          <li>
            <strong>Differentiate your app</strong> from the competition
          </li>
          <li>
            Make a great <strong>first impression</strong> even before the
            install
          </li>
          <li>
            Preview videos <strong>increase conversions up to 35%</strong>
          </li>
          <li>
            <strong>Showcase your app</strong> in less than a minute
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AppStoreScreenshotsCycle;
