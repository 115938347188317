import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import ExampleTemplate from "../../../Common/ExampleTemplate/ExampleTemplate";
import * as styles from "./templates.module.scss";

const AppStoreScreenshotsTemplates = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      template_1: file(
        relativePath: { eq: "illustrations/template-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1300
            placeholder: BLURRED
          )
        }
      }
      template_2: file(
        relativePath: { eq: "illustrations/template-2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1300
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  return (
    <div className={styles.container} id={'templates'}>
        <h3>
            2 pre-made App Store templates to get started
        </h3>
        <h4>
        Create beautiful panoramic mockup sets for your iOS and Android apps in minutes.
        </h4>
        <ExampleTemplate image={imageQuery.template_1} index={1} id={"54d6c8c5-8c83-4fc5-a1fa-97a19703439f"} alt="App Store panorama set with blue background and dark-blue phones"/>
        <ExampleTemplate image={imageQuery.template_2} index={2} id={"6942af31-bff5-46b5-8517-4b62ad526cee"} alt="App Store panorama set with multi-color background and white phones" />
        <div className={styles.browse}>
          <Link to={"/mockups/screenshots"}>
            <button>Browse all templates</button>
          </Link>
        </div>
    </div>
  );
};

export default AppStoreScreenshotsTemplates;
