import React from "react";
import TweetEmbed from 'react-tweet-embed'
import * as styles from "./testimonials.module.scss";

export default () => {

  return (
    <div className={styles.container}>
      <h3>You are in good company</h3>
      <h4>We helped thousands of designers and developers establish a professional App Store presence.</h4>
      <div className={styles.testimonials}>
        <TweetEmbed id={'1280635370556788743'} placeholder={null}/>
        <TweetEmbed id={'1288582076485251072'} placeholder={null} />
        <TweetEmbed id={'1288050756311044097'} placeholder={null}/>
      </div>
    </div>
  );
};
