import React from "react";
import TextLoop from "react-text-loop";
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "./header.module.scss";
import { Link } from "gatsby"

const AppStoreScreenshotsHeader = () => {

  function onClick(){
    document.getElementById('templates').scrollIntoView({
      behavior: 'smooth'
    });
  }


  return (
    <div className={styles.container}>
        <div className={styles.inner}>
      <div className={styles.left}>
        <h1>
          App Store Screenshot Generator <br />
          for{" "}
          <span className={styles.textloop}>
            <TextLoop
              mask={true}
              children={["Developers", "Marketers", "Designers"]}
            />
          </span>
        </h1>
        <h2>
            Create studio-quality screenshots for App Store & Google Play.
        </h2>
          <button onClick={onClick}>Browse Templates</button>
          <a className={styles.button2} href="https://blog.previewed.app/how-to-create-app-store-screenshots/">Read Tutorial</a>
      </div>
      <div className={styles.right}>
      <StaticImage
      src='../../../../assets/images/illustrations/app-store-screenshots.png'
      alt="Panoramic screenshots for App Store framed inside a white clay iPhone device"
      placeholder="blurred"
      layout="fixed"
      height={700}
    />
      </div>
  
      </div>
    </div>
  );
};

export default AppStoreScreenshotsHeader;
