import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./cycle.module.scss";

const AppStoreScreenshotsCycle = () => {
  return (
    <div className={styles.container}>
        <div className={styles.left}>
            <h3>It’s a virtuous cycle</h3>
            <h4>Optimized App Store listings get more views, downloads, ratings and higher rank.</h4>

            <ul>
                <li>Good first impression <strong>increases conversion by 35%</strong></li>
                <li>App video can <strong>increase installs by over 25%</strong></li>
                <li>50% of users <strong>drop-off</strong> within 6 seconds</li>
                <li>Average <strong>decision time</strong> is just 3 seconds</li>
                <li>60% of visitors don’t scroll beyond <strong>first impression</strong></li>
            </ul>
        </div>
        <div className={styles.right}>
        <StaticImage
     src='../../../../assets/images/illustrations/virtuous-cycle.png'
     alt="A cycle visualizing cause and effect, highlighting the importance of beautiful App Store screenshots"
     placeholder="blurred"
     layout="constrained"
     width={700}
   />
        </div>
    </div>
  );
};

export default AppStoreScreenshotsCycle;
